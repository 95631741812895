<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer">
      <v-list nav>
        <v-img
          :src="require('@/assets/logo.svg')"
          max-height="75"
          max-width="75"
          class="my-5"
        ></v-img>
        <v-list-item-group color="red">
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Visió global</v-list-item-title>
          </v-list-item>
          <v-list-item to="/gastos">
            <v-list-item-icon>
              <v-icon>mdi-currency-eur</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Gastos</v-list-item-title>
          </v-list-item>
          <v-list-item to="/reutilitzacio">
            <v-list-item-icon>
              <v-icon>mdi-cloud-download-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Reutilització de dades</v-list-item-title>
          </v-list-item>
          <v-list-item to="/about">
            <v-list-item-icon>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sobre el projecte</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="red" app flat>
      <v-app-bar-nav-icon @click="drawer = !drawer" class="white--text"></v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">Transparencia Manacor</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';

export default {
  name: "App",

  components: {
    // HelloWorld,
  },

  data: () => ({
    drawer: true,
  }),
};
</script>
