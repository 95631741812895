<template>
  <v-container>
    <v-card class="pa-4" max-width="400">
      <v-card-text class="pt-0">
        <div class="title font-weight-light mb-2">Documents processats</div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import data from "@/assets/providers.json";
export default {
  data: () => ({
    labels: ['Facturas de proveedores','hola'],
    value: [data.length,2],
  }),
};
</script>

<style>

</style>